import {BookInSearchResponse, getDownloadLink, libraryRequestFile, LibrarySearchResponse} from '../../api/api';
import styles from './library.module.scss';
import PendingModal from './PendingModal';
import React, {useMemo, useState} from 'react';

export const SearchResultsList = ( { searchResults }: { searchResults: LibrarySearchResponse }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const downloadFile = (id: number) => {
    const link = document.createElement("a");
    link.href = getDownloadLink(id);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onBookItemClicked = async (e: React.MouseEvent<HTMLAnchorElement>, bookId: number) => {
    e.preventDefault();
    setSelectedId(bookId);
    const result = await libraryRequestFile(bookId);
    if (!result) {
      setIsModalVisible(true);
      return;
    }
    downloadFile(bookId);
  }

  const makeBookTitleAuthor = (book: BookInSearchResponse) => {
    const authorLinks = book.authors.map((author) => (
      <a key={author.id} href="#">{author.name}</a>
    ))

    return (
      <li key={book.id}>
        <a
          href={getDownloadLink(book.id)}
          id={`book-${book.id}`}
          onClick={(e) => onBookItemClicked(e, book.id)}
        >
          {book.title}
        </a>
        <span>-</span>
        {authorLinks}
      </li>
    )
  };

  return (
    <div>

      <PendingModal
        isVisible={isModalVisible}
        id={selectedId}
        onClose={() => setIsModalVisible(false)}
        onFileReady={downloadFile}
      />

      <p>Authors</p>
      <ul className={styles.linkUl}>
        {searchResults.authors.map(author => <li key={author.id}>{author.name}</li>)}
      </ul>

      <p>Books</p>
      <ul className={styles.linkUl}>
        {searchResults.books.map(makeBookTitleAuthor)}
      </ul>
    </div>
  );
}
