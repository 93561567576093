
const BASE_API = 'https://yuriykalugin.ru'

export async function auth(login: string, password: string): Promise<string> {
  try {
    const response = await fetch(BASE_API + '/api/library/login', {
      body: JSON.stringify({ login, password }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    const data = await response.json();
    if (data.jwt) {
      return data.jwt;
    }
  } catch (e) {
    console.log(e);
    throw new Error('Something went wrong');
  }
  throw new Error('Wrong login/password');
}

const baseApi = async (path: string, requestInit?: RequestInit | undefined) => {
  const jwt = localStorage.getItem('jwt');
  if (!jwt) throw new Error('Not authorized');

  const response = await fetch(BASE_API + path, {
    ...requestInit,
    headers: {
      ...requestInit?.headers,
      Authorization: `Bearer ${jwt}`,
    }
  });
  if (response.status === 401) {
    throw new Error('Not authorized');

  }
  return response;
}

export type BookInSearchResponse = {
  id: number;
  title: string;
  authors: [{
    id: number;
    name: string;
  }]
}

export type LibrarySearchResponse = {
  authors: [{
    id: number;
    name: string;
  }],
  books: BookInSearchResponse[];
};

export async function librarySearch(query: string) {
  const response = await baseApi(`/api/library/search?query=${query}`);
  return await response.json() as LibrarySearchResponse;
}

export async function libraryRequestFile(bookId: number) {
  const response = await baseApi(`/api/library/requestFile?id=${bookId}`)
  return response.status !== 202;
}

export function getDownloadLink(bookId: number, isEpub = true) {
  return `${BASE_API}/api/library/download?id=${bookId}&epub=${isEpub}`;
}

function parseAttachmentString(input: string | null): string | null {
  if (!input) { return null; }

  const regex = /attachment; filename=([^;]+)/;
  const match = input.match(regex);

  return match ? match[1] : null;
}
