import React from 'react';
import {IMaskInput} from 'react-imask';
import {Input, InputLabel, InputProps} from '@mui/material';

interface MaskedTextFiledProps extends InputProps {
  label?: string;
  mask: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, any>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        definitions={{
          '#': /[1-9]/,
        }}
        unmask={true}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const MaskedTextField = (props: MaskedTextFiledProps) => {
  return (
    <div>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Input
        {...props}
        inputProps={{ mask: props.mask }}
        inputComponent={TextMaskCustom as any}
      />
    </div>
  )
}

export default MaskedTextField;
