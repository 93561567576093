import React from 'react';
import {Dialog} from '@mui/material';
import {libraryRequestFile} from '../../api/api';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './library.module.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onFileReady: (id: number) => void;
  id: number;
}

const TIMER_TIMEOUT = 15000;

export default class PendingModal extends React.Component<Props, never> {

  private timer: any = undefined;

  private timerTick = async () => {
    const result = await libraryRequestFile(this.props.id);
    if (result) {
      this.props.onFileReady(this.props.id);
      this.props.onClose();
    } else {
      this.timer = setTimeout(this.timerTick, TIMER_TIMEOUT);
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!prevProps.isVisible && this.props.isVisible) {
      this.timer = setTimeout(this.timerTick, TIMER_TIMEOUT);
    } else if (prevProps.isVisible && !this.props.isVisible) {
      clearTimeout(this.timer);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return (
      <Dialog open={this.props.isVisible} onClose={this.props.onClose}>
        <div className={styles.modalContainer}>
          <CircularProgress />
          <div className={styles.textInModal}>
            File is not ready yet. You can wait, but it may take a while...
          </div>
        </div>
      </Dialog>
    )
  }
}

