import React, {useState} from 'react';
import {Pointer} from './MallocSimulatorEngine';
import styles from './mallocSimulator.module.scss';

export type AllocatedMemoryInfo = {
  ptr: Pointer;
  size: number;
}

type AllocatedViewProps = {
  allocated: Array<AllocatedMemoryInfo>
  onDeallocatePressed: (index: number) => void;
}

export default function AllocatedView(props: AllocatedViewProps) {
  const [selected, setSelected] = useState<number | null>(null);

  const onFreeClicked = () => {
    if (selected === null) return;
    props.onDeallocatePressed(selected);
    setSelected(null);
  };

  const renderEntry = (item: AllocatedMemoryInfo, index: number) => {
    const classNames = [styles.allocatedMemoryInfo];

    if (index === selected) {
      classNames.push(styles.allocatedMemoryInfoSelected);
    }

    return (
      <div key={index}
           onClick={() => setSelected(index)}
           className={classNames.join(' ')}
      >
        {`${index}: ${item.ptr} [${item.size}]`}
      </div>
    );
  };

  return (
    <div style={{marginLeft: 10}}>
      <button onClick={onFreeClicked} disabled={selected === null}>
        Free
      </button>
      <div>
        {props.allocated.map(renderEntry)}
      </div>
    </div>
  )
}

