

/*
        "merchantUid": "test",
        "mcc": "2311",
        "description": "some description",
        "amount": 100.10,
        "currency": "USD",
        "cardNumber": "7100010000000151",
        "dateExp": "032030",
        "cvv": "998",
        "cardholderName": "Yuriy Kalugin"
 */

type makeTransactionParams = {
  amount: number,
  cardNumber: string,
  dateExp: string,
  cvv: string,
  cardholderName: string,
};

export const makeTransaction = async (param: makeTransactionParams) => {
  const response = await fetch('https://yuriykalugin.ru/dev/card-processing/startTransaction', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'participantUid': 'test',
    },
    body: JSON.stringify({
      ...param,
      description: 'Test transaction from WEB',
      currency: 'USD',
      mcc: '1188',
      merchantUid: 'test',
    })
  });
  if (response.status !== 200) throw new Error('Transaction rejected');
  return (await response.json()).transactionUid;
}

export const commitTransaction = async (transactionUid: string) => {
  const response = await fetch(`https://yuriykalugin.ru/dev/card-processing/commitTransaction/${transactionUid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'participantUid': 'test',
      },
    });
  if (response.status !== 200) throw new Error('Transaction failed');
}
