import React, {ChangeEvent, useContext, useState} from 'react';
import {Button, TextField} from '@mui/material';
import styles from './payWithCardTest.module.scss';
import CardContainer from './cardContainer';
import {commitTransaction, makeTransaction} from '../../api/payTest';
import {TopMessageContext} from '../../components/topBarMessage';

const PayWithCardTest = () => {

  const topBarContext = useContext(TopMessageContext);

  const [state, setState] = useState({
    isLoading: false,
    amount: '100',
    cardNumber: '7100010000000078',
    cardholderName: '',
    cvv: '',
    dateExp: '',
  });

  const isButtonEnabled =
     Number.parseInt(state.amount) > 0
      && state.cardNumber
      && state.dateExp
      && state.cvv
      && state.cardholderName;


  const onAmountChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!newValue) {
      setState((oldState) => ({ ... oldState, amount: '' }));
      return;
    }
    if (/^\d+((\.)\d{0,2})?$/.test(newValue)) {
      setState((oldState) => ({ ... oldState, amount: newValue }));
    }
  };

  const onPayClick = async () => {
    setState({...state, isLoading: true});
    const params = {
      amount: Number.parseInt(state.amount),
      cardholderName: state.cardholderName,
      dateExp: state.dateExp,
      cvv: state.cvv,
      cardNumber: state.cardNumber,
    }
    try {
      const transactionUid = await makeTransaction(params);

      await commitTransaction(transactionUid);
      topBarContext.setMessage('ok', 'Success');
    } catch (e: any) {
      console.error(e);

      topBarContext.setMessage('error', e.message);
    } finally {
      setState({...state, isLoading: false, cvv: '' });
    }
  };

  return (

    <div className={styles.container}>

      <div className={styles.withMargin}>
        Enter the amount and card detail to test payment
      </div>

      <div className={styles.withMargin}>
        <TextField
          value={state.amount}
          label="Enter amount"
          variant="outlined"
          size="small"
          onChange={onAmountChange}
        />
      </div>

      <CardContainer
        isLoading={state.isLoading}
        cardNumber={state.cardNumber}
        cardholderName={state.cardholderName}
        cvv={state.cvv}
        dateExp={state.dateExp}
        onChange={(field, value) => setState((oldState) => ({...oldState, [field]: value}))}
      />

      <div className={styles.withMargin}>
        <Button variant="contained"
                onClick={onPayClick}
                disabled={!isButtonEnabled}>
          Pay
        </Button>
      </div>
    </div>
  )
}

export default PayWithCardTest;
