import React, {useMemo, useRef, useState, MouseEvent} from 'react';
import {MallocSimulatorEngine} from './MallocSimulatorEngine';
import styles from './mallocSimulator.module.scss';
import AllocatedView, { AllocatedMemoryInfo } from './AllocatedView';

const SIZE = 102;

export function MallocSimulatorPage(props: unknown) {
  const mallocSimulator = useMemo(() => new MallocSimulatorEngine(), []);
  const [allocValue, setAllocValue] = useState('100');
  const [allocatedMemoryInfo, setAllocatedMemoryInfo] = useState<Array<AllocatedMemoryInfo>>([]);

  const [groups, setGroups] = useState<Array<Array<boolean>>>(Array.from(new Array(SIZE), () => [false]));

  const update = () => {
    const chunks = mallocSimulator.listChunks();

    const groups = new Array<Array<boolean>>();
    let currentGroup = new Array<boolean>();
    let i = 0;

    for (let chunk of chunks) {
      let startBlock = Math.floor(chunk.offset / 100);
      let endBlock = Math.floor((chunk.offset + chunk.size) / 100);
      while (i < startBlock) {
        groups.push([false]);
        i++;
      }
      currentGroup = new Array<boolean>(endBlock - startBlock + 1);
      for (let q = startBlock; q <= endBlock; q++) {
        currentGroup[q - startBlock] = true;
        i++;
      }
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }
      currentGroup = new Array<boolean>();
    }
    while (i < SIZE) {
      groups.push([false]);
      i++;
    }
    setGroups(groups);
  };

  const onDeallocatePressed = (index: number) => {
    mallocSimulator.free(allocatedMemoryInfo[index].ptr);
    update();
    allocatedMemoryInfo.splice(index, 1)
    setAllocatedMemoryInfo(allocatedMemoryInfo);
  };

  const onMallocClick = () => {
    const numAllocValue = Number(allocValue);
    if (isNaN(numAllocValue)) return;
    const ptr = mallocSimulator.malloc(numAllocValue);
    if (ptr === null) { return; }
    update();
    setAllocatedMemoryInfo([...allocatedMemoryInfo, { ptr, size: numAllocValue }]);
  };

  return (
    <div className={styles.container}>

      <MemoryView2 group={groups} />

      <div className={styles.twoColumns}>
        <div>
          <input type="number" value={allocValue} onChange={(e) => setAllocValue(e.target.value)}/>
          <button onClick={onMallocClick}>
            MALLOC
          </button>
        </div>

        <AllocatedView onDeallocatePressed={onDeallocatePressed} allocated={allocatedMemoryInfo} />
      </div>

    </div>
  );
}

const MemoryCell = React.memo(RawMemoryCell);

function RawMemoryCell(props: { isRed?: boolean}) {
  return (
    <div className={styles.memoryCellContainer}>
      <div className={styles.memoryCell} style={{ backgroundColor: props.isRed ? 'red' : undefined}} />
    </div>
  )
}

function MemoryView2(props: { group: Array<Array<boolean>> }) {
  return (
    <div className={styles.memoryBlocksContainer}>
      {props.group.map((group, idx) => (
        <div key={idx} className={styles.group}>
          {group.map((cell, idx) => (
            <MemoryCell key={idx} isRed={cell} />
          ))}
        </div>
      ))}
    </div>
  );
}

