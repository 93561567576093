import { Buffer} from 'buffer';

export type Pointer = number | null

export type Chunk = {
  offset: number;
  size: number;
  next: number | null;
}

const RAM = 1024 * 10;
const FIRST_CHUNK_SIGN = 0x8714FEA1;
const CHUNK_SIZE = 6;
const MIN_MALLOC_SIZE = 44;

export class MallocSimulatorEngine {

  private memory = Buffer.allocUnsafe(RAM);

  malloc(req_size: number): Pointer {
    const size = Math.max(req_size, MIN_MALLOC_SIZE) + CHUNK_SIZE;
    if (size >= RAM - 4) return null;
    const firstTime = this.memory.readUint32BE(0) !== FIRST_CHUNK_SIGN;
    if (firstTime) {
      this.memory.writeUint32BE(FIRST_CHUNK_SIGN, 0);
      this.writeChunk(4, size);
      return 4 + CHUNK_SIZE;
    } else {
      return this.insertChunk(size);
    }
  }

  free(ptr: Pointer) {
    if (ptr === null) return;

    const iterator = this.iterateChunks();
    let iterResult;
    let previousChunk;
    while (!(iterResult = iterator.next()).done) {
      const chunk = iterResult.value;
      if (chunk.offset === ptr - CHUNK_SIZE) {
        const { offset, next } = chunk;
        this.writeChunk(offset, 0, next);
        if (previousChunk) {
          this.writeChunk(previousChunk.offset, previousChunk.size, next);
        }
        break;
      }
      previousChunk = chunk;
    }
  }

  listChunks() {
    let result = Array<Chunk>();
    if (this.memory.readUint32BE(0) !== FIRST_CHUNK_SIGN) return [];
    let next: Pointer = 4;
    while (next !== null) {
      const chunk = this.readChunk(next);
      if (chunk === null) break;
      result.push(chunk);
      next = chunk.next;
    }
    return result;
  }

  private insertChunk(size: number): Pointer {
    const iterator = this.iterateChunks();
    let chunkIter = iterator.next();
    let currentChunk = chunkIter.value!;
    let prevChunk: Chunk | null = null;

    while (!(chunkIter = iterator.next()).done) {
      prevChunk = currentChunk;
      currentChunk = chunkIter.value;
      const freeSpace = currentChunk.offset - (prevChunk.offset + prevChunk.size);
      if (freeSpace > size) {
        const offset = prevChunk.offset + prevChunk.size;
        if (prevChunk.size > 0) {
          this.writeChunk(offset, size, currentChunk.offset);
          this.writeChunk(prevChunk.offset, prevChunk.size, offset);
        } else {
          this.writeChunk(offset, size, currentChunk.offset);
        }
        return offset + CHUNK_SIZE;
      }
    }
    const offset = currentChunk.offset + currentChunk.size;
    if (offset + size >= RAM) return null;
    if (currentChunk.size > 0) {
      this.writeChunk(offset, size);
      this.writeChunk(currentChunk.offset, currentChunk.size, offset);
    } else {
      this.writeChunk(offset, size, currentChunk.next);
    }
    return offset + CHUNK_SIZE;
  }

  private writeChunk(ptr: Pointer, size: number, next: Pointer = null) {
    const numPtr = ptr as number;
    this.memory.writeUint16BE(numPtr, numPtr);
    this.memory.writeUint16BE(size, numPtr + 2);
    this.memory.writeUint16BE(next ?? 0, numPtr + 4);
  }

  private readChunk(ptr: Pointer): Chunk | null {
    const numPtr = ptr as number;
    const offset = this.memory.readUint16BE(numPtr);
    const size = this.memory.readUint16BE(numPtr + 2);
    const next = this.memory.readUint16BE(numPtr + 4) || null;
    if (offset !== numPtr) return null;
    return {
      offset,
      size,
      next,
    }
  }

  private iterateChunks(): Iterator<Chunk, Chunk | null> {
    const noFirstChunk =  (this.memory.readUint32BE(0) !== FIRST_CHUNK_SIGN);

    let next: Pointer = 4;

    return {
        next: () => {
          if (next === null || noFirstChunk) {
            return {done: true, value: null}
          }
          const chunk = this.readChunk(next);
          if (!chunk || noFirstChunk) {
            return {done: true, value: null}
          }
          next = chunk.next;
          return {
            value: chunk!,
            done: false
          }
        }
      }
    }
}
