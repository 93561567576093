import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss';

export type TopBarMessageType = 'ok' | 'warning' | 'error';

type ShowMessageFunc = (type: TopBarMessageType, message: string) => void;

type TopMessageContextType = {
  setMessage: ShowMessageFunc,
  message: string,
  type: TopBarMessageType,
}

export const TopMessageContext = React.createContext<TopMessageContextType>({
  message: '',
  type: 'ok',
  setMessage: (type: TopBarMessageType, message: string) => {},
});

const getColor = (type: TopBarMessageType) => {
  switch (type) {
    case 'error': return 'red';
    case 'warning': return 'orange';
    default: return 'green';
  }
}

const TopBarMessage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutId = useRef<any>(null);
  const context = useContext(TopMessageContext);

  useEffect(() => {
    clearTimeout(timeoutId.current);
    if (!context.message) {
      setIsVisible(false);
      return;
    }
    setIsVisible(true)
    timeoutId.current = setTimeout(() => {
      context.setMessage('ok', '');
      setIsVisible(false)
    }, 5000);

  }, [context])


  return (
    <div className={styles.topBarContainer}
      style={{ top: isVisible ? 0 : '-100px', background: getColor(context.type)}}
    >
      {context.message}
    </div>
  );
};

export default TopBarMessage;
