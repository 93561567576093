import React, {useState} from 'react';
import {redirect} from 'react-router-dom';

export default function InnerStartPage(props: unknown) {
    const presToLogout = () => {
        localStorage.clear();
        redirect('/');
    }

    return (
      <div>
          <button onClick={presToLogout}>
              Press to logout
          </button>
      </div>
    );
}
