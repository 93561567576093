import React, {ChangeEvent} from 'react';
import {InputLabel, TextField} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './payWithCardTest.module.scss';
import MaskedTextField from './maskedTextField';

type CardContainerProps = {
  cardNumber: string,
  dateExp: string,
  cardholderName: string,
  cvv: string,
  isLoading: boolean,
  onChange: (field: 'cardNumber' | 'dateExp' | 'cardholderName' | 'cvv', value: string) => void;
}

const CardContainer = (props: CardContainerProps) => {
  const {
    cardNumber,
    dateExp,
    cardholderName,
    isLoading,
    cvv,
  } = props;

  const onChange = (field: 'cardNumber' | 'dateExp' | 'cardholderName' | 'cvv') => {
    return (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      props.onChange(field, e.target.value);
    }
  }

  return (
    <div className={styles.cardAndCvvContainer}>
      <div className={styles.cardContainer}>

        {isLoading && (
          <div className={styles.loadingOverlay}>
            <CircularProgress />
          </div>
        )}

        <MaskedTextField
          mask="0000 0000 0000 0000"
          placeholder="Card number"
          style={{ fontSize: '24px'}}
          className={styles.botMargin}
          value={cardNumber}
          onChange={onChange('cardNumber')}
        />

        <TextField
          placeholder="Cardholder name"
          inputProps={{ style: { fontSize: '24px'} }}
          variant="standard"
          value={cardholderName}
          onChange={onChange('cardholderName')}
        />


        <div className={styles.dateExpRow}>

          <div>
            <InputLabel style={{ marginRight: '5px'}}>CVV</InputLabel>
            <MaskedTextField
              mask="000"
              type="password"
              style={{ width: '2em'}}
              value={cvv}
              onChange={onChange('cvv')}
            />
          </div>

          <div>
            <InputLabel style={{ marginRight: '5px'}}>Date exp.</InputLabel>
            <MaskedTextField
              mask="00 / 0000"
              style={{ width: '5em'}}
              value={dateExp}
              onChange={onChange('dateExp')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardContainer;
