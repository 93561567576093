import {Input, TextField} from '@mui/material';
import {useState} from 'react';
import {librarySearch, LibrarySearchResponse} from '../../api/api';
import { SearchResultsList } from './SearchResultsList';
import styles from './library.module.scss';

export default function LibraryPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResponse, setSearchResponse] = useState<LibrarySearchResponse | null>(null);

  const search = async () => {
    const search = await librarySearch(searchQuery);
    setSearchResponse(search);
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') search();
  }

  return (
    <div className={styles.libraryContainer}>
      Library page

      <div>
        Search query
      </div>
      <TextField
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={onKeyDown}
      />

      {searchResponse && (
        <SearchResultsList searchResults={searchResponse} />
      )}

    </div>
  )
};
